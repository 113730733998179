import React from 'react';

import Layout from '../components/Layout';

const ImprintPage = () => (
    <Layout fullMenu>
        <article id="main">
            <header>
                <h2>Impressum</h2>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <h3>Angaben gemäß § 5 TMG</h3>
                    <p>
                        Dipl. Psych. / Dipl. Soz.-Päd. Robin Schiel<br/>
                        Psychologischer Psychotherapeut (VT)
                    </p>
                    <p>
                        Bergheimer Str. 89/1<br/>69115 Heidelberg
                    </p>
                    <h3>Kontakt</h3>
                    <p>
                        Tel: 06221 - 90 336 41<br/>mail&#160;<span className="icon solid fa-at">&#160;</span>robinschiel.de
                    </p>

                    <h3>Berufsbezeichung und berufsrechtliche Regelungen</h3>
                    <p>Berufsbezeichnung: Psychologischer Psychotherapeut</p>


                    <h3>Aufsichtsbehörde</h3>
                    <p>
                        Landespsychotherapeutenkammer Baden-Württemberg<br/>
                        Jägerstr. 40<br/>
                        70174 Stuttgart<br/>
                        Tel.-Nr.: 0711-674470-0<br/>
                        Fax-Nr.: 0711-674470- 15<br/>
                        http://www.lpk-bw.de
                    </p>

                    <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h3>
                    <p>
                        Dipl. Psych. / Dipl. Soz.-Päd. Robin Schiel<br/>
                        Bergheimer Str. 89/1<br/>
                        69115 Heidelberg
                    </p>

                    <h3>Erteilung der Approbation als Psychologischer Psychotherapeut</h3>
                    <p>
                        Regierungspräsidium Stuttgart<br/>
                        Ruppmannstr. 21<br/>
                        70565 Stuttgart<br/>
                        Telefon 0711 – 9040<br/>
                        Fax 0711 – 90411190<br/>
                        E-Mail poststelle@rps.bwl.de
                    </p>

                    <h3>Vertragsärztliche Zulassung</h3>
                    <p>
                        Kassenärztliche Vereinigung Baden-Württemberg (KVBW)<br/>
                        Albstadtweg 11<br/>
                        70567 Stuttgart<br/>
                        Telefon 0711 – 78750<br/>
                        Fax 0711 – 78753274<br/>
                        E-Mail hauptverwaltung@kvbawue.de
                    </p>

                    <h3>Streitschlichtung</h3>
                    <p>
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                    </p>

                    <h3>Haftung für Inhalte</h3>
                    <p>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    </p>
                    <p>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>

                    <h3>Haftung für Links</h3>
                    <p>
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                        haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                        erkennbar.
                    </p>
                    <p>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Links umgehend entfernen.
                    </p>

                    <h3>Urheberrecht</h3>
                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                        nicht kommerziellen Gebrauch gestattet.
                    </p>

                    <p>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                        umgehend entfernen.
                    </p>
                    <p>
                        Quelle: <a href="https://www.e-recht24.de" target="_blank">https://www.e-recht24.de</a>
                    </p>

                    <h3>Fotos</h3>
                    <p>
                        <a
                        href="https://www.sarahhaehnle.de/" target="_blank">Sarah Hähnle</a>
                    </p>

                    <h3>Logogestaltung</h3>
                    <p>
                        <a
                            href="http://www.teresalehmann.com/" target="_blank">Teresa Lehmann</a>
                    </p>

                    <h3>Website</h3>
                    <p>
                        <a
                        href="https://www.teqneers.de/" target="_blank">TEQneers GmbH & Co. KG</a>
                    </p>
                </div>
            </section>
        </article>
    </Layout>
);

export default ImprintPage;
